import { useEffect } from 'react';

// third-party
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

// do not remove imports below as they are
// used globally in all other pages ...

import 'service/AxiosService';

// project import
import Snackbar from 'components/@extended/Snackbar';
import Loader from 'components/Loader';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Routes from 'routes';
import { setAuthDetail, setTokens } from 'store/reducers/auth';
import ThemeCustomization from 'themes';
import utils from 'utils/utils';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    bindInitData();
  }, []);

  const bindInitData = async () => {
    const token = utils.getTokensFromStorage();
    if (token) {
      const currentTokenUser = jwtDecode(token.session);
      dispatch(setAuthDetail({ user: currentTokenUser, token }));
    } else {
      dispatch(setTokens({ token: null }));
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <Routes />
          <Snackbar />
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
