// project import
import menu from './menu';
import order from './order';
import admin from './admin';
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

export const navigation = {
  items: [dashboard, admin, menu, order]
};
