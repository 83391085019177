// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { MenuOutlined, CategoryOutlined, BurstModeOutlined, DoDisturbOutlined, EditNoteOutlined, Filter } from '@mui/icons-material';
import enums from 'utils/enums';

// icons
const icons = {
  MenuOutlined,
  CategoryOutlined,
  BurstModeOutlined,
  DoDisturbOutlined,
  EditNoteOutlined,
  Filter
};

// ==============================|| MENU ITEMS - MAIN ||============================== //
const menu = {
  id: 'mobile-application',
  type: 'group',
  title: <FormattedMessage id='application' />,
  roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin, enums.userRole.User, enums.userRole.SiteManager],
  children: [
    {
      id: 'banners',
      title: <FormattedMessage id='banners' />,
      subTitle: 'Manage Banners',
      type: 'item',
      url: 'mobile-application/banners',
      icon: icons.BurstModeOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true
    },
    {
      id: 'screensavers',
      title: <FormattedMessage id='screensavers' />,
      subTitle: 'Manage Screen Savers',
      type: 'item',
      url: 'mobile-application/screensavers',
      icon: icons.Filter,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true
    },
    {
      id: 'submenulist',
      title: <FormattedMessage id='submenulist' />,
      subTitle: 'Manage Categories',
      type: 'item',
      url: 'mobile-application/submenu',
      icon: icons.CategoryOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true
    },
    {
      id: 'menulist',
      title: <FormattedMessage id='menulist' />,
      subTitle: 'Manage Menu Items',
      type: 'item',
      url: 'mobile-application/menu',
      icon: icons.MenuOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true
    },
    {
      id: 'disableditemlist',
      title: <FormattedMessage id='disabledlist' />,
      subTitle: 'Manage Disabled Items',
      type: 'item',
      url: 'mobile-application/disableditem',
      icon: icons.DoDisturbOutlined,
      isShowFilter: true
    },
    {
      id: 'modifieritemlist',
      title: <FormattedMessage id='modifierlist' />,
      subTitle: 'Manage Modifiers',
      type: 'item',
      url: 'mobile-application/modifieritems',
      icon: icons.EditNoteOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true
    }
  ]
};

export default menu;
