// third-party
import { FormattedMessage } from 'react-intl';

// assets

import enums from 'utils/enums';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { ShoppingCartOutlined, StarOutlineOutlined } from '@mui/icons-material';
// icons
const icons = {
  ShoppingCartOutlined,
  StarOutlineOutlined,
  RateReviewOutlinedIcon
};

// ==============================|| MENU ITEMS - MAIN ||============================== //

const order = {
  id: 'order',
  type: 'group',
  title: <FormattedMessage id='orderList' />,
  roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin, enums.userRole.User, enums.userRole.SiteManager],
  children: [
    {
      id: 'orderList',
      title: <FormattedMessage id='orderList' />,
      type: 'item',
      url: 'order/orders',
      icon: icons.ShoppingCartOutlined,
      isShowFilter: true,
      subTitle: 'Manage Orders'
    },
    {
      id: 'feedback',
      title: <FormattedMessage id='feedback' />,
      type: 'item',
      url: 'order/feedback',
      icon: icons.StarOutlineOutlined,
      isShowFilter: true
    },
    {
      id: 'sitefeedback',
      title: <FormattedMessage id='sitefeedback' />,
      type: 'item',
      url: 'order/sitefeedback',
      icon: icons.RateReviewOutlinedIcon,
      isShowFilter: true
    }
  ]
};

export default order;
