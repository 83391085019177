import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));

// render main pages
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics.js')));
const SyncData = Loadable(lazy(() => import('pages/dashboard/syncData.js')));
const MenuList = Loadable(lazy(() => import('pages/menu/menuList.js')));
const CategoryList = Loadable(lazy(() => import('pages/menu/categoryList.js')));
const OrderList = Loadable(lazy(() => import('pages/order/orderList.js')));
const SystemUserList = Loadable(lazy(() => import('pages/admin/systemUserList.js')));
const SiteList = Loadable(lazy(() => import('pages/site/siteList.js')));
const SiteDetail = Loadable(lazy(() => import('pages/site/siteDetail.js')));
const OrderDetail = Loadable(lazy(() => import('pages/order/orderDetail.js')));
const OrderRatings = Loadable(lazy(() => import('pages/feedback/orderRatings.js')));
const SiteFeedbacks = Loadable(lazy(() => import('pages/feedback/siteFeedbacks.js')));
const Rules = Loadable(lazy(() => import('pages/rule/rules.js')));
const BannerList = Loadable(lazy(() => import('pages/banner/bannerList.js')));
const AddEditRule = Loadable(lazy(() => import('pages/rule/addEditRule.js')));
const AccessDenied = Loadable(lazy(() => import('pages/maintenance/accessDenied.js')));
const DisabledPosItemList = Loadable(lazy(() => import('pages/menu/disabledPosItemList.js')));
const ModifierItemsList = Loadable(lazy(() => import('pages/menu/modifierItemsList.js')));
const ScreenSaverList = Loadable(lazy(() => import('pages/screen-saver/screenSaverList.js')));
const PushNotification = Loadable(lazy(() => import('pages/notification/notificationRule.js')));
const NotifyCustomerList = Loadable(lazy(() => import('pages/notification/notifyCustomerList.js')));
const EmployeeList = Loadable(lazy(() => import('pages/pos/employeeList.js')));
const DeviceList = Loadable(lazy(() => import('pages/devices/deviceList.js')));
const Receipt = Loadable(lazy(() => import('pages/receipt/receipt.js')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'sync',
              element: <SyncData />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },
        {
          path: 'admin',
          children: [
            {
              path: 'users',
              element: <SystemUserList />
            },
            {
              path: 'sites',
              element: <SiteList />
            },
            {
              path: 'rules',
              element: <Rules />
            },
            {
              path: 'notification',
              element: <PushNotification />
            },
            {
              path: 'employees',
              element: <EmployeeList />
            },
            {
              path: 'devices',
              element: <DeviceList />
            }
          ]
        },
        {
          path: 'mobile-application',
          children: [
            {
              path: 'banners',
              element: <BannerList />
            },
            {
              path: 'submenu',
              element: <CategoryList />
            },
            {
              path: 'menu',
              element: <MenuList />
            },
            {
              path: 'disableditem',
              element: <DisabledPosItemList />
            },
            {
              path: 'modifieritems',
              element: <ModifierItemsList />
            },
            {
              path: 'screenSavers',
              element: <ScreenSaverList />
            }
          ]
        },
        {
          path: 'order',
          children: [
            {
              path: 'orders',
              element: <OrderList />
            },
            {
              path: 'feedback',
              element: <OrderRatings />
            },
            {
              path: 'sitefeedback',
              element: <SiteFeedbacks />
            }
          ]
        },
        {
          path: '/maintenance',
          element: <CommonLayout />,
          children: [
            {
              path: 'access-denied',
              element: <AccessDenied />
            },
            {
              path: '404',
              element: <MaintenanceError />
            },
            {
              path: '500',
              element: <MaintenanceError500 />
            },
            {
              path: 'under-construction',
              element: <MaintenanceUnderConstruction />
            }
          ]
        },
        {
          path: 'detail/:orderId',
          element: <OrderDetail />
        },
        {
          path: 'site/info/:siteId',
          element: <SiteDetail />
        },
        {
          path: 'addEditRule/save/:conditionId?',
          element: <AddEditRule />
        },
        {
          path: 'execute/:notificationRuleId',
          element: <NotifyCustomerList />
        }
      ]
    },
    {
      path: '/receipt/:orderId',
      element: <Receipt />
    }
  ]
};

export default MainRoutes;
