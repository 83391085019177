import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import AddUser from 'pages/admin/addSystemUser';
import ResetAdminPassword from 'pages/admin/resetAdminPassword';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { login } from 'store/reducers/auth';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const onSave = (payload) => {
    const token = { session: payload.sessionToken, refresh: payload.refreshToken };
    dispatch(login({ user: payload.user, token }));
    setOpenResetPasswordDialog(false);
    setOpenEditProfileDialog(false);
  };

  return (
    <List component='nav' sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(e) => {
          handleListItemClick(e, 0);
          e.stopPropagation();
          setOpenEditProfileDialog(true);
        }}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary='Edit Profile' />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(e) => {
          handleListItemClick(e, 1);
          e.stopPropagation();
          setOpenResetPasswordDialog(true);
        }}
      >
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary='Reset Password' />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </ListItemButton>
      {openResetPasswordDialog && (
        <ResetAdminPassword onCancel={() => setOpenResetPasswordDialog(false)} systemUser={user} onSave={onSave} />
      )}
      {openEditProfileDialog && <AddUser onCancel={() => setOpenEditProfileDialog(false)} user={user} onSave={onSave} page='editProfile' />}
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
