// third-party
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  roles: []
};

// ==============================|| SLICE - MENU ||============================== //

const lookup = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setLookup(state, action) {
      state.roles = action.payload;
    },

    clearLookup(state) {
      state.roles = [];
    }
  }
});

export default lookup.reducer;

export const { setLookup, clearLookup } = lookup.actions;
