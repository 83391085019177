// material-ui
import logoIcon from 'assets/images/logos/cj_logo.svg';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  return <img src={logoIcon} alt='CJ Sales' width={120} height={'auto'} />;
};

export default LogoMain;
