// third-party
import { FormattedMessage } from 'react-intl';

// assets

import { DashboardCustomizeOutlined } from '@mui/icons-material';
import enums from 'utils/enums';
// icons
const icons = {
  DashboardCustomizeOutlined
};

// ==============================|| MENU ITEMS - MAIN ||============================== //

const dashboard = {
  id: 'dashboard',
  type: 'group',
  title: <FormattedMessage id='dashboard' />,
  roleTags: [enums.userRole.SuperAdmin],
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id='dashboard' />,
      type: 'item',
      url: 'dashboard/analytics',
      icon: icons.DashboardCustomizeOutlined,
      subTitle: 'Dashboard'
    }
  ]
};

export default dashboard;
