import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

// material-ui
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/reducers/auth';
import ProfileTab from './ProfileTab';

// assets
import { LogoutOutlined } from '@ant-design/icons';
import avatar1 from 'assets/images/users/avatar-1.png';
import enums from 'utils/enums';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role='tabpanel' hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const handleLogout = async () => {
    dispatch(logout(true));
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const roleName = enums.AdminRole[user.role];
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label='open profile'
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Stack direction='row' spacing={2} alignItems='center' sx={{ p: 0.5 }}>
          <Avatar alt='profile user' src={avatar1} size='xs' />
          <Typography variant='subtitle1' color='white'>
            {user.name}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type='fade' in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <Stack direction='row' spacing={1.25} alignItems='center'>
                            <Avatar alt='Profile User' src={avatar1} sx={{ width: 32, height: 32 }} />
                            <Stack direction='row' spacing={1.5} alignItems='center'>
                              <Stack spacing={0}>
                                <Typography variant='subtitle1'>{user.name}</Typography>
                                <Typography variant='caption' color='textSecondary'>
                                  {roleName}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Tooltip title='Logout'>
                            <IconButton size='large' sx={{ color: 'text.primary' }} onClick={handleLogout}>
                              <LogoutOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <ProfileTab handleLogout={handleLogout} />
                      </Box>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
